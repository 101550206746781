import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/utils/fliter'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, dataList, releaseDescription, releasedate) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['17%', '3%', '38%', '10%', '3%', '29%'],
              body: [
                [
                  { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: data.circular_memo_no, style: 'td', alignment: 'left' },
                  { text: vm.$t('globalTrans.date'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: (dateFormat(releasedate.training_end_date)) + ' ' + (vm.$t('elearning_tim.ad')), style: 'td', alignment: 'left' }
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
      pdfContent.push({ text: (dateFormat(releasedate.training_start_date)) + ' ' + (vm.$t('elearning_tim.release1')) + ' ' + (dateFormat(releasedate.training_end_date)) + ' ' + (vm.$t('elearning_tim.release2')) + ' ' + '“' + (vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title) + '”' + ' ' + (vm.$t('elearning_tim.release3')) + ' ' + (dateFormat(releasedate.training_end_date)) + ' ' + (vm.$t('elearning_tim.release4')), style: 'hh', alignment: 'left' })
    //   pdfContent.push({ text: vm.$t('globalTrans.details'), style: 'hh', alignment: 'center' })
      const allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.name'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.mobile'), style: 'th', alignment: 'center' },
          { text: vm.$t('globalTrans.designation'), style: 'th', alignment: 'center' },
          { text: vm.$t('elearning_tim.posting_office'), style: 'th', alignment: 'center' }
        ]
      ]
      dataList.filter((data, optionIndex) => {
        allRows.push(
          [
            { text: vm.$n(optionIndex + 1), style: 'td', alignment: 'center' },
            { text: (vm.$i18n.locale === 'bn' ? data.name_bn : data.name), style: 'td', alignment: 'center' },
            { text: (vm.$i18n.locale === 'bn' ? '০' : '0') + vm.$n(data.mobile, { useGrouping: false }), style: 'td', alignment: 'center' },
            { text: (vm.$i18n.locale === 'bn' ? data.designation_name_bn : data.designation_name), style: 'td', alignment: 'center' },
            { text: ((vm.$i18n.locale === 'bn' ? data.office_name_bn : data.office_name)) + ', ' + ((vm.$i18n.locale === 'bn' ? data.org_name_bn : data.org_name)), style: 'td', alignment: 'center' }
          ]
        )
      })
      pdfContent.push({ text: '', style: 'tableMargin' })
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['8%', '18%', '15%', '15%', '44%'],
          body: allRows
        }
      })
      pdfContent.push({ text: releaseDescription.description, style: 'hh', alignment: 'center' })
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'Protrait',
      watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n === 'bn') ? 10 : 8,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n === 'bn') ? 10 : 8,
          margin: [3, 3, 3, 3]
        },
        header: {
          fontSize: 16,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 0]
      },
      org: {
          fontSize: 13,
          bold: true,
          alignment: 'center',
          margin: [0, -25, 0, 0]
      },
      address: {
          fontSize: 11,
          margin: [0, 0, 0, 5]
      },
      hh: {
          fontSize: (i18n === 'bn') ? 10 : 8,
        //   bold: true,
          margin: [10, 10, 25, 20]
      },
        header2: {
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        fertilizer: {
          fontSize: 10,
          margin: [10, 10, 0, 20]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 0]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 4]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).download('allowance-details')
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
